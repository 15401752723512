'use strict'
import AbortController from "abort-controller"
import errorService from '@/services/errorService'
import * as dot from 'dot-object'

const service = config.VUE_APP_service_licenze || ''
const apiKey = config.VUE_APP_licenze_Api_Key || 'xxx'
const cliente = config.VUE_APP_licenze_Cliente || 'xxx'
const isService = 'false'

async function cicleSync() {
  try {
    const response = await fetch(`${service}/cicleSync`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    return await response.json()
  } catch (error) {
    return null
  }
}

const fetchTimeout = (url, ms, ac, { signal, ...options } = {}) => {
  const promise = fetch(url, { signal: signal, ...options });
  const timeout = setTimeout(() => ac.abort(), ms);
  return promise.finally(() => {
    clearTimeout(timeout)
  });
};

function getServiceBase() {
  return service
}

// TODO: ATTENZIONE: Questo token è relativo al backoffice, non al backoffice licenze. Vanno separati !! Per adesso preso dai valori restituiti dalla login !!
async function getToken() {
  // construire la audience in base ai pacchetti necessari
  const audience = 'configurazioni,prodotti,base'
  const controller = new AbortController();
  var result  
  try {
    const timeout = 3000

    result = await fetchTimeout(`${service}/jwt?id=${cliente}&key=${apiKey}&aud=${audience}&service=${isService}&ver=v2`, timeout, controller, { // 5000
      signal: controller.signal,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    const token = await result.json()
  } catch (error) {
    if (result && result.status === 403) {
      winston.error('getToken: Licenza scaduta')
      response.json('Licenza scaduta')
    } else {
      winston.error(`getToken: Errore lettura token da Licenze. Service:${service}, Cliente=${cliente}, key=${apiKey}, service=${isService} - Errore : ${error}`)
      response.json('')
    }
  }
}

// Deve chiamare direttamente il server delle licenze online
async function verifyToken(token) {
  if (!token) return { stato: 'token.scaduto' }
  if (token === 'Licenza scaduta') return { stato: 'licenza.scaduta' }
  let licenza = {}
  try {
    const response = await fetch(`${service}/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
    const result = await response.json()
    licenza = result
  } catch (err) {
    // verificare risposta in base a token (es. scaduto)
    // console.log(err)
    licenza.stato = 'token.scaduto'
  }

  return licenza
}

async function login(utente) {
  try {
    const response = await fetch(`${service}/user/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(utente)
    })
    if (response.ok) {
      return await response.json()
    } else {
      if (response.status === 401) {
        return { error: true, message: 'Credenziali non corrette' }
      } else {
        return { error: true, message: 'Errore di rete. Contattare l\'amministratore' }
      }
    }
  } catch (err) {
    console.log('errore login', err)
  }
}

async function register(utente) {
  // const { first_name, last_name, email, password, company } = utente
  try {
    const data = utente
    const response = await fetch(`${service}/user/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    if (response.ok) {
      return await response.json()
    } else {
      switch(response.status) {
        case 400: 
          return { error: true, message: `E' necessario compilare tutti i campi` }
        case 409:
          return { error: true, message: 'Utente esistente, effettualre la login' }
        case 500:
          return { error: true, message: `Errore di rete, contattare l'amministratore` }
        default:
          return { error: true, message: `Si è verificato un errore nella registrazione, contattare l'amministratore` }
      }
    }
  } catch (err) {
    console.log('errore login', err)
    return { error: true, message: `Si è verificato un errore nella registrazione, contattare l'amministratore` }
  }
}


async function getApiKey() {
  try {
    const response = await fetch(`${service}/apikey`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    let result = await response.json()
    return result
  } catch (err) {
    console.log(err)
    return ''
  }
}

async function getShortApiKey() {
  try {
    const response = await fetch(`${service}/shortapikey`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    let result = await response.json()
    return result
  } catch (err) {
    console.log(err)
    return ''
  }
}

async function creaLicenza(codiceCliente, licenza) {
  try {
    const data = {
      codiceCliente,
      licenza
    }
    const response = await fetch(`${service}/licence/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function aggiornaLicenza(codiceCliente, licenza) {
  try {
    const data = {
      codiceCliente,
      licenza
    }
    const response = await fetch(`${service}/licence/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function listCustomerDatabase(base) {
  try {
    const response = await fetch(`${service}/database/${base}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function listCustomerDatabaseWithInfo(base) {
  try {
    const response = await fetch(`${service}/database/${base}/infoall`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function creaDbVuoti(baseDb) {
  try {
    const response = await fetch(`${service}/database/${baseDb}/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function creaDbCollector(baseDb) {
  try {
    const response = await fetch(`${service}/database/${baseDb}/collector`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function copiaDbEsistenti(baseDb, source) {
  try {
    const response = await fetch(`${service}/database/clone/${source}/${baseDb}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function removeDatabase(dbname) {
  try {
    const response = await fetch(`${service}/database/remove/${dbname}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}
async function removeAllDatabases(baseDb) {
  try {
    const response = await fetch(`${service}/database/removeall/${baseDb}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function createBaseSettings(base, destination) {
  try {
    const response = await fetch(`${service}/config/${base}/${destination}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function copyDatabase(source, destination) {
  try {
    const response = await fetch(`${service}/database/${source}/${destination}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function aggiornaImpostazioniCliente(codiceCliente, licenza, variabile, valore) {
  try {
    dot.str(variabile, valore, licenza)
    return await aggiornaLicenza(codiceCliente, licenza)
  } catch (err) {
    return {}
  }
}

async function sanitizeDb(dbname, aggiornadb) {
  try {
    const response = await fetch(`${service}/utility/sanitize/${dbname}?aggiorna=${aggiornadb}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function sanitizeClienti(dbname, aggiornadb) {
  try {
    const response = await fetch(`${service}/utility/sanitize/clienti/${dbname}?aggiorna=${aggiornadb}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}


async function aggiornaGiacenza(dbname, aggiornadb, magazziniMultipli) {
  try {
    const response = await fetch(`${service}/utility/giacenza/${dbname}?aggiorna=${aggiornadb}&mm=${magazziniMultipli}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function creaInventarioDaGiacenza(dbname, aggiornadb, magazziniMultipli) {
  try {
    const response = await fetch(`${service}/utility/inventario/giacenza/${dbname}?aggiorna=${aggiornadb}&mm=${magazziniMultipli}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function correggeMovimentiScontrino(dbname, aggiornadb) {
  try {
    const response = await fetch(`${service}/utility/revise/movement/${dbname}?aggiorna=${aggiornadb}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function eliminaProdottiObsoleti(dbname, dataLimite, aggiornadb) {
  try {
    const data = {
      date: dataLimite
    }
    const response = await fetch(`${service}/utility/cleaning/obsolete/${dbname}?aggiorna=${aggiornadb}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function aggiungeCategoriaDefault(dbname, aggiornadb) {
  try {
    const response = await fetch(`${service}/utility/category/default/${dbname}?aggiorna=${aggiornadb}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function creaCategorieMancanti(dbname, aggiornadb) {
  try {
    const response = await fetch(`${service}/utility/category/missing/${dbname}?aggiorna=${aggiornadb}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}


async function getElencoProgetti() {
  const response = await fetch(`${service}/projects/list`)
  return await response.json()
}

async function getElencoProgettiCliente(apikey) {
  const response = await fetch(`${service}/projects?key=${apikey}&ver=v2&env=true`)
  return await response.text()
}

async function creaUtente(codiceCliente, utente) {
  try {
    const data = {
      codiceCliente,
      utente
    }
    const response = await fetch(`${service}/user/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function aggiornaUtente(codiceCliente, utente) {
  try {
    const data = {
      codiceCliente,
      utente
    }
    const response = await fetch(`${service}/user/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function cancellaUtente(codiceCliente, utente) {
  try {
    const data = {
      codiceCliente,
      utente
    }
    const response = await fetch(`${service}/user/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function creaCloudantApiPairs() {
  try {
    const response = await fetch(`${service}/database/cloudant/apikey`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function statoElaborazione(tipo) {
  try {
    const response = await fetch(`${service}/elaborazione/stato`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return err
  }
}

export default {
  cicleSync,
  getServiceBase,
  getToken,
  verifyToken,
  login,
  register,
  getApiKey,
  getShortApiKey,
  creaLicenza,
  aggiornaLicenza,
  listCustomerDatabase,
  listCustomerDatabaseWithInfo,
  creaDbVuoti,
  creaDbCollector,
  copiaDbEsistenti,
  removeDatabase,
  removeAllDatabases,
  createBaseSettings,
  copyDatabase,
  aggiornaImpostazioniCliente,
  sanitizeDb,
  sanitizeClienti,
  aggiornaGiacenza,
  creaInventarioDaGiacenza,
  correggeMovimentiScontrino,
  eliminaProdottiObsoleti,
  aggiungeCategoriaDefault,
  creaCategorieMancanti,
  getElencoProgetti,
  getElencoProgettiCliente,
  creaUtente,
  aggiornaUtente,
  cancellaUtente,
  creaCloudantApiPairs,
  statoElaborazione
}
