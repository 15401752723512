<template>
  <v-text-field
    :label="item.label"
    v-model="item.value"
    :rules="rulesValidation"
    :maxlength="item.maxlength ? item.maxlength: 1000"
    :counter="item.maxlength ? item.maxlength : false"
    :disabled="disabled || item.readonly"
    :prefix="item.prefix ? item.prefix : ''"
    @change=onChange>
  </v-text-field>
</template>

<script>
  import { validazioni } from '@/mixins/validazioni.js'
  
  export default {
    name: 'testo',
    mixins: [
      validazioni
    ],
    props: {
      item: Object,
      disabled: { type: Boolean, default: false }
    },
    data: (vm) => ({
    }),
    methods: {
      onChange(value) {
        if (this.item.prefix) {
          value = `${this.item.prefix}${value}`
        }
        this.$emit('change', { model: this.item.model, subModel: this.item.subModel, value: value })
      }
    }
  }
</script>