<template>
  <v-navigation-drawer
    v-model="show"
    app
    :mini-variant.sync="mini"
    permanent
    :disable-route-watcher="true"
    :disable-resize-watcher="true"
    @transitionend="transitionend">
    <template v-slot:append>
      <v-list-item>
        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-list-item>
    </template>
    <v-list-item class="px-2" two-line>
      <v-list-item-avatar>
        <!-- <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img> -->
        <!-- <v-img src="https://randomuser.me/api/portraits/women/81.jpg"></v-img> -->
        <v-img src="/img/ubisell-logo-round.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{fullname || 'Ubisell'}}</v-list-item-title>
        <v-list-item-subtitle v-if="user" class="text-caption text-decoration-underline cursor-pointer" @click="onLogout">Logout</v-list-item-subtitle>
        <v-list-item-subtitle v-if="!user" class="text-caption text-decoration-underline cursor-pointer" @click="onLogin">Login</v-list-item-subtitle>
      </v-list-item-content>
      <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-item
        v-for="[icon, text, href] in licensedLinks"
        :to="href"
        :key="icon"
        :title="text"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    links: [
      ['mdi-home', 'Home', '/'],
/*       ['mdi-archive-outline', 'Articoli', '/v-articoli'], */
      ['mdi-account-outline', 'Clienti', '/v-clienti', 'clienti'],
      ['mdi-account-cog-outline', 'Service', '/v-service', 'service'], // 
/*       ['mdi-book-outline', 'Movimenti', '/v-movimenti'],
      ['mdi-database-import-outline', 'Import', '/v-import'],
      ['mdi-printer', 'Stampe', '/v-stampe'],
      ['mdi-chart-bar-stacked', 'Statistiche', ''], */
      ['mdi-cog-outline', 'Configurazione', '/v-configurazione' , 'configurazione'],
      ['mdi-account-group-outline', 'Rivenditori', '/v-rivenditori' , 'rivenditori']
    ],
    show: true,
    mini: true
  }),
  watch: {
    showDrawer(value) {
      if (value) {
        this.show = true
      }
    }
  },
  computed: {
    showDrawer() {
      return this.$store.getters.leftDrawer
    },
    user() {
      return this.$store.getters['utenti/user']
    },
    fullname() {
      return this.user ? `${this.user.first_name} ${this.user.last_name}` : ''
    },
    moduliLicenza() {
      // return this.$store.getters.moduliLicenza
      // TODO: Verificare
      return this.$store.getters.localModuliLicenza
    },
    licensedLinks() {
      return this.links.filter(x => !x[3] || this.moduliLicenza.includes(x[3]))
    }    
  },
  methods: {
    t(codice, defaultValue) {
      const value = this.$vuetify.lang.t(`$vuetify.${codice}`)
      return value === `$vuetify.${codice}` ? defaultValue || '' : value || defaultValue
    },    
    onLogout() {
      this.$store.commit('utenti/logout')
      this.$store.commit('CLEAR_MODULI_LICENZA')
      if (this.$route.name !== 'Home') {
        this.$router.push('/')
      }
      this.mini = false
    },
    onLogin() {
      this.$router.push('/login')
    },
    transitionend() {
      this.$store.commit('SET_LEFT_DRAWER', false)
    },
    toggleRight() {
      // solo per debug poi togliere
      this.$store.commit('SET_RIGHT_DRAWER', true)
    }
  }
}
</script>

<style lang="scss" scoped>
  .cursor-pointer {
    cursor: pointer;
  }
</style>
